/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState, useContext} from 'react'
import {toast} from 'react-hot-toast'
import {baseUrl, sendRequest} from '../../../Helpers/Requests'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {useSubscriptionPayment} from '../../hooks/useSubscriptionPayment'
import ConfigContext from '../../contexts/Config/ConfigContext'
import {ModalPixSendPlano} from './ModalPixSendPlano'

const Planos: FC = () => {
  const config = useContext(ConfigContext)
  const navigate = useNavigate()
  const subscriptionPayment = useSubscriptionPayment()
  const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
  const [selected, setSelected] = useState<any>(null)

  const [subscription, setSubscription] = useState<any>(null)
  const [configurations, setConfigurations] = useState<any>({})
  const [plansList, setPlansList] = useState([])
  const [selectedPlan, setSelectedPlan] = useState({})
  const [pixModal, setPixModal] = useState(false)

  const [buttons, setButtons] = useState<any>([])
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false)

  const [grupos, setGrupos] = useState([])
  const [currentGroup, setCurrentGroup] = useState(null)

  const loadConfig = async () => {
    const req = await sendRequest('rede/configuracoes', 'GET')
    const response = await req

    if (response.tipo === 'success') {
      setConfigurations({
        binario: response.binario,
        residual: response.residual,
        unilevel: response.unilevel,
      })
    }
  }

  const loadPlans = async () => {
    const req = await sendRequest('planos', 'GET')
    const response = await req

    if (response.tipo === 'success') {
      setPlansList(response.planos)
      setSelected(response.planos[0])
      setGrupos(response.grupos)
      let newButtons: any = []
      response.planos[0].buttons.forEach((btn: any) => {
        let newButton = btn
        newButton.loading = false
        newButtons.push(newButton)
      })
      setButtons(newButtons)
    }
  }

  const renderPlan = (plan: any, index: any) => {
    return (
      <div
        onClick={() => {
          setSelected(plan)
        }}
        className={
          `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex plan-container flex-stack text-start p-6 mb-6 ` +
          (plan.id === selected.id && 'active btn-active btn-active-primary')
          // active -> classe plano ativo
          // (!plan.custom && 'btn-active btn-active-primary ')
        }
        data-bs-toggle='tab'
        data-bs-target={`#kt_upgrade_plan_1`}
        key={index}
      >
        <div className='d-flex align-items-center me-2'>
          <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
            <input
              className='form-check-input'
              type='radio'
              name='plan'
              value={plan.nome}
              checked={selected.nome === plan.nome}
              onChange={(e) => setSelected(e.target.value)}
            />
          </div>

          <div className='flex-grow-1 me-2'>
            <img src={baseUrl(plan.image.src)} />
          </div>

          <div className='flex-grow-1'>
            <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
              <span className={plan.id === selected.id ? 'text-light notranslate' : 'notranslate'}>
                {plan.nome}
              </span>

              {plan.label && (
                <span className='badge badge-light-success ms-2 fs-7'>{plan.label}</span>
              )}
            </h2>
            <div className='fw-bold opacity-50'>{plan.mid.description}</div>
          </div>
        </div>

        <div className='ms-5'>
          <>
            <span className='mb-2'>{config.moeda.prefixo}</span>

            <span className='fs-3x fw-bolder'>{plan.valor}</span>

            <span className='fs-7 opacity-50'>
              /<span data-kt-element='period'>un</span>
            </span>
          </>
        </div>
      </div>
    )
  }

  const openInNewTab = (href: any) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click()
  }

  const gerarLinkDePagamento = async (button: any, index: any) => {

    if (grupos.length > 0 && currentGroup == null) {
      return toast.error('Selecione uma duração para o contrato...')
    }

    let actualButtons = [...buttons]
    actualButtons[index].loading = true
    setButtons(actualButtons)
    setDisabledButtons(true)

    const response = await subscriptionPayment.buyPlan(selected.id, button, currentGroup)

    if (button.method === 'Pix') {
      setSubscription(response.subscription)
      let actualButtons = [...buttons]
      actualButtons[index].loading = false
      setButtons(actualButtons)
      setDisabledButtons(false)
      return setPixModal(true)
    }
    navigate('/minha-assinatura')
  }

  const setBuyGroup = (group:any) => {
    console.log(group)
  }

  useEffect(() => {
    loadConfig()
    loadPlans()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Planos</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush'>
            <div className='card-body d-flex flex-column pt-5'>
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>Selecione seu plano.</h1>

                <div className='text-muted fw-bold fs-5'>
                  Selecione seu plano e o método de pagamento abaixo.
                </div>
              </div>

              <div className='row'>
                <div className='col-12 col-xl-6 mb-10 mb-lg-0'>
                  <div className='nav plans-column-render'>
                    {plansList.map((plan, index) => renderPlan(plan, index))}
                  </div>
                </div>

                <div className='col-12 col-xl-6 plans-details-render'>
                  <div className='tab-content rounded h-100 bg-light p-10'>
                    <div className={`tab-pane fade show active`} id={`kt_upgrade_plan_1`} key={1}>
                      <div className='pb-5'>
                        <h2 className='fw-bolder text-dark'>
                          {selected != null ? selected.nome : 'Selecione um plano'}
                        </h2>

                        <div className='text-gray-400 fw-bold'>
                          {selected != null && 'Benefícios incluídos no plano:'}
                        </div>
                      </div>

                      {selected !== null && (
                        <>
                          <div className='pt-1'>
                            <div className={`d-flex align-items-center mb-7`}>
                              <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                Acesso à plataforma
                              </span>

                              <KTSVG
                                path='/media/icons/duotune/general/gen043.svg'
                                className='svg-icon-1 svg-icon-success'
                              />
                            </div>
                          </div>
                          {/* {config.diario && (
                            <div className='pt-1'>
                              <div className={`d-flex align-items-center mb-7`}>
                                <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                  Ganhos diários até render {selected.type.porcentagem_teto}% do
                                  valor investido
                                </span>

                                <KTSVG
                                  path='/media/icons/duotune/general/gen043.svg'
                                  className='svg-icon-1 svg-icon-success'
                                />
                              </div>
                            </div>
                          )} */}
                          {config.binario && (
                            <div className='pt-1'>
                              <div className={`d-flex align-items-center mb-7`}>
                                <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                  Ganho binário caso seja qualificado
                                </span>

                                <KTSVG
                                  path='/media/icons/duotune/general/gen043.svg'
                                  className='svg-icon-1 svg-icon-success'
                                />
                              </div>
                            </div>
                          )}
                          {config.indicacao && (
                            <div className='pt-1'>
                              <div className={`d-flex align-items-center mb-7`}>
                                <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                  Ganho por indicação de novos membros
                                </span>

                                <KTSVG
                                  path='/media/icons/duotune/general/gen043.svg'
                                  className='svg-icon-1 svg-icon-success'
                                />
                              </div>
                            </div>
                          )}
                          {config.unilevel && (
                            <div className='pt-1'>
                              <div className={`d-flex align-items-center mb-7`}>
                                <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                  Ganho unilevel dos membros da rede
                                </span>

                                <KTSVG
                                  path='/media/icons/duotune/general/gen043.svg'
                                  className='svg-icon-1 svg-icon-success'
                                />
                              </div>
                            </div>
                          )}
                          <hr />
                          <div className='pt-1'>
                            <div className={`d-flex align-items-center`}>
                              <span className='fw-bold fs-5 text-white flex-grow-1'>
                                Duração do contrato:
                              </span>
                            </div>
                          </div>
                          <div className='d-flex flex-wrap flex-row-fluid pt-2'>
                            {grupos.map((btn: any, index: any) => (
                              <button
                                key={`btn${index}`}
                                title={btn.title}
                                className={
                                  buttons.length <= 3
                                    ? '  '
                                    : '  '
                                }
                                onClick={() => setCurrentGroup(btn.id)}
                                disabled={btn.loading || disabledButtons}
                                style={{ color: '#fff', backgroundColor: (currentGroup == btn.id) ? '#169226' : '#323248', padding: 5, border: 0, borderRadius: '7px', marginRight: 5 }}
                              >
                                {btn.loading ? (
                                  <>
                                    {buttons.length <= 3 ? (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Aguarde um instante...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    ) : (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        <span
                                          className='spinner-border spinner-border-sm align-middle'
                                          style={{width: '25px', height: '25px'}}
                                        ></span>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className='ms-3'>{btn.nome}</span>
                                  </>
                                )}
                              </button>
                            ))}
                          </div>
                          <hr />

                          <div className='pt-1'>
                            <div className={`d-flex align-items-center`}>
                              <span className='fw-bold fs-5 text-white flex-grow-1'>
                                Pagar Com:
                              </span>
                            </div>
                          </div>
                          <div className='d-flex flex-wrap flex-row-fluid pt-2'>
                            {buttons.map((btn: any, index: any) => (
                              <button
                                key={`btn${index}`}
                                type='reset'
                                title={btn.title}
                                className={
                                  buttons.length <= 3
                                    ? 'btn btn-secondary btn-sm me-3 mb-3'
                                    : 'btn btn-icon btn-secondary btn-sm me-3 mb-3'
                                }
                                onClick={() => gerarLinkDePagamento(btn, index)}
                                disabled={btn.loading || disabledButtons}
                              >
                                {btn.loading ? (
                                  <>
                                    {buttons.length <= 3 ? (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Aguarde um instante...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    ) : (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        <span
                                          className='spinner-border spinner-border-sm align-middle'
                                          style={{width: '25px', height: '25px'}}
                                        ></span>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <img src={btn.prepend_image} style={{height: '25px'}} />
                                    {buttons.length <= 3 && (
                                      <span className='ms-3'>{btn.title}</span>
                                    )}
                                  </>
                                )}
                              </button>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selected && (
        <ModalPixSendPlano
          valor={(subscription)?(subscription.difference !== null)?subscription.difference:subscription.plan.valor:selected.valor}
          pixModal={pixModal}
          hidePixModal={() => setPixModal(false)}
          subscription={subscription}
          redirectAfter='/minha-assinatura'
        />
      )}
    </>
  )
}

export {Planos}
