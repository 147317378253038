/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useContext} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ConfigContext from '../../contexts/Config/ConfigContext';
import { baseUrl } from '../../../Helpers/Requests';

const AuthLayout = () => {
  const config = useContext(ConfigContext);
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <video autoPlay={true} loop={true} style={{position: "absolute", width: "100vw", height: "100vh", objectFit: "cover", zIndex: -1}}>
        <source src="https://apiemail.bckcode.com.br/bg-login.mp4" type="video/mp4" />
      </video>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1' >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10' style={{backgroundColor: "rgba(255, 255, 255, 0.13)", backdropFilter: "blur(10px)", borderRadius: "14px"}}>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        {config.footer.mostrar && (
          <div className='d-flex flex-center flex-wrap px-5'>
            {/* begin::Links */}
            <div className='d-flex fw-semibold text-primary fs-base '>
              &copy; {config.footer.texto} &nbsp;<a className='opacity-75-hover text-warning' href={config.footer.url}>{config.footer.criado_por}</a>
            </div>
            {/* end::Links */}
          </div>
        )}
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      
    </div>
  )
}

export {AuthLayout}
