/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG} from '../../../../helpers'

type Props = {
  className: string
  assinatura?: any
  saldoTotal?: any
  rendimento?: any
  bonificacao?: any
  binario?: boolean
  ganhoDiario?: any
  dataDiario?: any
  config?: any
  carreira?: any
  pontosCarreira?: any
  loaded?: any
}

const rows: Array<{description: string}> = [
  {description: 'Avg. Client Rating'},
  {description: 'Instagram Followers'},
  {description: 'Google Ads CPC'},
]

const ListsWidget26 = ({
  className,
  assinatura,
  saldoTotal,
  rendimento,
  bonificacao,
  binario,
  ganhoDiario,
  dataDiario,
  config,
  carreira,
  pontosCarreira,
  loaded,
}: Props) => (
  <div className={`card card-flush ${className} ${loaded ? '' : 'overlay overlay-block'}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Detalhes</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-3'>
      <div className='overlay-wrapper'>
        <Fragment key={`lw26-rows-1`}>
          <div className='d-flex flex-stack'>
            <a href='#' className='text-primary fw-semibold fs-6 me-2'>
              Saldo Total: {config.moeda.prefixo} {parseFloat(saldoTotal).toFixed(2)}
              <span className='opacity-50 d-block'>
                Rendimentos: {config.moeda.prefixo} {parseFloat(rendimento).toFixed(2)}
              </span>
              <span className='opacity-50 d-block'>
                Bonificações: {config.moeda.prefixo} {parseFloat(bonificacao).toFixed(2)}
              </span>
            </a>
          </div>
          <div className='separator separator-dashed my-3' />
        </Fragment>

        {!config.binario && config.carreira && (
          <Fragment key={`lw26-rows-carreira`}>
            <div className='d-flex flex-stack'>
              <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                Carreira: {carreira}{' '}
                <span className='fs-smaller text-warning ml-1'>{pontosCarreira} pts</span>
              </a>
            </div>
            <div className='separator separator-dashed my-3 me-10' />
          </Fragment>
        )}

        {/* {config.diario && ganhoDiario !== null && (
          <Fragment key={`lw26-rows-5`}>
            <div className='d-flex flex-stack'>
              <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                Ganho Diário: {config.moeda.prefixo} {parseFloat(ganhoDiario[0]).toFixed(2)} ({ganhoDiario[1]}%)
              </a>
            </div>
            <div className='separator separator-dashed my-3' />
          </Fragment>
        )} */}

        {config.diario && (
          <Fragment key={`lw26-rows-6`}>
            <div className='d-flex flex-stack'>
              <a href='#' className='text-primary fw-semibold fs-6 me-2'>
                Data diário: {dataDiario}
              </a>
            </div>
          </Fragment>
        )}
      </div>
      {!loaded && (
        <div className='overlay-layer rounded bg-dark bg-opacity-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Carregando...</span>
          </div>
        </div>
      )}
    </div>
  </div>
)
export {ListsWidget26}
